"use client"
import styled from 'styled-components';
// import { device } from '../utils/deviceSizes';

export const ErrorPageStyles = styled.div`

  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  
`